import baseRequest from "@/utils/request";

const defaultOpt: object = {
    login: true
};

let request = {
    post: (url: string, params = {}, options = {}) => {
        let param = Object.assign({
            url,
            data: params,
            method: "post"
        }, defaultOpt, options)
        return baseRequest(param);
    },
    get: (url: string, params = {}, options = {}) => {
        let param = Object.assign({
            url,
            params,
            method: "get"
        }, defaultOpt, options)
        return baseRequest(param);
    },
}


//分类首页
export function getArticles(id: string) {
    return request.get('/h5api/articles/index', { id }, { login: false })
}


//子集分类数据 子集ID
export function getArticlesChildren(id: string, page: number) {
    return request.get('/h5api/articles/childarticles', { id, page }, { login: false })
}


//子集分类数据 子集ID
export function getArticlesDetail(article_id: string) {
    return request.get('/h5api/articles/details', { article_id }, { login: false })
}


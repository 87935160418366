import axios from 'axios'
import {
    Toast
} from 'vant'; // 优化加载提示，当用户正在登陆中提示
// 全局响应错误处理

import $store from "../store";

let request = axios.create({
    baseURL: process.env.VUE_APP_URL,
    timeout: 20000
})

interface HEADERS {
    "Content-Type": string,
}
interface OPTIONS {
    headers?: HEADERS,
    login?: boolean,
    url: string
}
function baseRequest(options: OPTIONS) {
    const token = $store.state.token;
    const headers: HEADERS = options.headers ? options.headers : {
        "Content-Type": '',
    };
    headers['Content-Type'] = 'application/json';
    options.headers = headers;
    if (options.login && !token) {
        return new Promise((resolve, reject) => {
            Toast('未登录')
        })
    }
    return request(options).then(res => {
        const data = res.data || {};
        if ([410000, 410001, 410002].indexOf(data.code) !== -1) {
            if (options.login === false) {
                return Promise.reject(data);
            }
            Toast(data.msg)
            return Promise.reject({ msg: '未登录', data: {} })
        } else if (data.code === 200 || data.code === 0) {
            return Promise.resolve(data);
        } else {
            return Promise.reject(data);
        }
    });
}



// /**
//  * http 请求基础类
//  * 参考文档 https://www.kancloud.cn/yunye/axios/234845
//  *
//  */
// request = ["post", "put", "patch"].reduce((request, method) => {
//     /**
//      *
//      * @param url string 接口地址
//      * @param data object get参数
//      * @param options object axios 配置项
//      * @returns {AxiosPromise}
//      */
//     request[method] = (url, data = {}, options = {}) => {
//         return baseRequest(
//             Object.assign({ url, data, method }, defaultOpt, options)
//         );
//     };
//     return request;
// }, {});

// ["get", "delete", "head"].forEach(method => {
//     /**
//      *
//      * @param url string 接口地址
//      * @param params object get参数
//      * @param options object axios 配置项
//      * @returns {AxiosPromise}
//      */
//     request[method] = (url, params = {}, options = {}) => {
//         return baseRequest(
//             Object.assign({ url, params, method }, defaultOpt, options)
//         );
//     };
// });

export default baseRequest